/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { Text, Box, Flex, Grid } from 'theme-ui';
import cloudinaryHelper from '../helpers/cloudinaryHelper';
import Masonry from 'react-masonry-component';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const PhotosModule = ({ photos, bizName }) => {
  const [lightbox, setLightbox] = useState({
    photoIndex: 0,
    isOpen: false,
  });

  const [order, setOrder] = useState(0);

  // const normalizePhotos = () => {
  //   const albums = photos;

  //   const ret = albums.items.map(album => {
  //     return [...album.images];
  //   });

  //   return [].concat.apply([], ret);

  // };

  const cloudinaryBuilder = (photo, width) => {
    const str = `https://res.cloudinary.com/gonation/${photo.cloudinaryId}`;
    return cloudinaryHelper(str, 1200);
  };

  useEffect(() => {
    setOrder(1);
  }, []);

  const getRandomSpan = () => (Math.random() < 0.5 ? 3 : 2);

  return (
    <Box sx={{ textAlign: 'center' }}>
      {lightbox.isOpen && (
        <Lightbox
          mainSrc={cloudinaryBuilder(photos[lightbox.photoIndex], 1800)}
          nextSrc={cloudinaryBuilder(
            photos[(lightbox.photoIndex + 1) % photos.length]
          )}
          prevSrc={cloudinaryBuilder(
            photos[(lightbox.photoIndex + photos.length - 1) % photos.length]
          )}
          onCloseRequest={() => setLightbox({ isOpen: false })}
          onMovePrevRequest={() =>
            setLightbox({
              photoIndex:
                (lightbox.photoIndex + photos.length - 1) % photos.length,
            })
          }
          onMoveNextRequest={() =>
            setLightbox({
              photoIndex: (lightbox.photoIndex + 1) % photos.length,
            })
          }
        />
      )}
      {/* <Text sx={{ fontSize: 1, fontWeight: '500' }}>Experience</Text>
      <Text variant="heading" sx={{ fontSize: [5,5,6], fontWeight: 'heading' }}>The Gallery</Text> */}
      <Grid
        sx={{
          gridGap: '12px',
          gridTemplateColumns: 'repeat( auto-fill, minmax( 300px, 1fr ) )',
          gridAutoRows: '300px',
          padding: '18px',
          paddingTop: 0,
        }}>
        {photos.map((el, idx) => (
          <img
            sx={{
              // gridRowEnd: `span ${idx % 4 === 0 ? 3 : 2}`,
              width: '100%',
              height: '100%',
              // maxHeight: '200px',
              objectFit: 'cover',
              boxShadow: '0 0 12px rgba(0,0,0,.25)',
              cursor: 'pointer',
            }}
            src={cloudinaryBuilder(el)}
            alt={el.name ? el.name : bizName}
            onClick={() =>
              setLightbox({
                isOpen: true,
                photoIndex: idx,
              })
            }
          />
        ))}
      </Grid>
    </Box>
  );
};

export default PhotosModule;
