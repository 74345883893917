/** @jsx jsx */
import React, { useState, useEffect, useRef } from 'react';
import { jsx } from 'theme-ui';
import { Text, Box, Flex } from 'theme-ui';
import '../components/styles/mapbox.css';
import getGoogleStr from '../helpers/getGoogleStr';
import SocialIcons from '../components/SocialIcons';
import addHTTP from '../helpers/addHTTP';

const AboutComponent = ({ data }) => {
  const { name } = data;

  const getGonationURL = () =>
    `https://gonation.com/place/${data.slug}/overview`;

  const renderDescription = desc => {
    if (desc.length > 1200) {
      return (
        <Text>
          {desc.slice(0, desc.length - 1200)}...{' '}
          <Text sx={{ color: '#111' }} as="a" href={getGonationURL()}>
            Read More
          </Text>
        </Text>
      );
    } else return desc;
  };

  const isCommercial = () =>
    data.categories.filter(category => category.includes('Commercial')).length >
    0;

  const getLink = () =>
    isCommercial()
      ? handleCommercialCase(data.name)
      : addHTTP(data.contact.url);

  const handleCommercialCase = name => {
    if (
      name.includes('117') ||
      name.includes('119') ||
      name.includes('129') ||
      name.includes('132B') ||
      name.includes('137')
    ) {
      return null;
    } else if (name.includes('68')) {
      return 'https://res.cloudinary.com/gonation/image/upload/v1704382921/gonation.data.prod/business/bzn-013eqVi-SrCEAt7TbFuzCQ/pdfs/68_Washington_Street.pdf';
    } else if (name.includes('128')) {
      return 'https://res.cloudinary.com/gonation/image/upload/v1704467352/gonation.data.prod/business/bzn-kNJ80u6TSlS_iwkB8EUU6A/pdfs/128_Washington_St_-_Brochure_2023-compressed_1.pdf';
    } else {
      return `/${data.name}.pdf`;
    }
  };

  return (
    <Flex sx={{ flexWrap: 'wrap', minHeight: ['300px', '400px', '600px'] }}>
      {/* <Box sx={{ width: ['100%', '50%'] }}>
        <div ref={refContainer}></div>
      </Box> */}
      <Box
        sx={{
          width: ['100%'],
          padding: [3, 3, 5],
          mt: 0,
          paddingTop: `0!important`,
          margin: 'auto',
        }}
      >
        <Text as="h2" variant="heading" sx={{ fontSize: [4], mb: 4 }}>
          {name}
        </Text>
        <Text as="p" sx={{ fontSize: 2, lineHeight: 2, mb: 4 }}>
          {data.description ? renderDescription(data.description) : ''}
        </Text>
        <Box sx={{ paddingY: 3, paddingBottom: 5 }}>
          {getLink() ? (
            <Text
              as="a"
              href={getLink()}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                padding: '16px 64px',
                border: '2px solid',
                borderColor: 'text',
                transition: `all .25s`,
                '&:hover': {
                  boxShadow: `2px 2px 8px rgba(0,0,0,.2)`,
                  transition: `all .25s`,
                },
              }}
            >
              {isCommercial() ? 'Visit Space Details' : 'Visit Website'}
            </Text>
          ) : (
            ''
          )}
        </Box>

        {!isCommercial() ? (
          <>
            <Text sx={{ fontSize: 2, fontWeight: '600', mb: 4 }}>
              <Text
                as="a"
                sx={{
                  color: 'text',
                  fontWeight: 500,
                  textDecoration: 'none',
                }}
                target="_blank"
                href={getGoogleStr(
                  data.name,
                  data.location.address.street,
                  data.location.address.city,
                  data.location.address.zip,
                  data.location.address.state
                )}
              >
                {data.location.address.street} <br />
                {data.location.address.city}, {data.location.address.state}
              </Text>
            </Text>
            <Text sx={{ fontSize: 2, fontWeight: '600', mb: 4 }}>
              <Text
                as="a"
                sx={{ color: 'text', fontWeight: 500 }}
                href={`tel:${data.contact.phone}`}
              >
                {data.contact.phone}
              </Text>
            </Text>
            <Box>
              <SocialIcons
                iconWidth="24px"
                iconFill="#111"
                links={data.contact}
                slug={data.slug}
                justifyLeft
                iconMargin="15px"
              />
            </Box>
          </>
        ) : (
          ''
        )}
      </Box>
    </Flex>
  );
};

export default AboutComponent;
