export default url => {
  if (!url) {
    return '';
  }
  const prefix = 'http://';
  if (url.includes('https')) {
    return url;
  }
  if (url.substr(0, prefix.length) !== prefix) {
    return prefix + url;
  }
  return url;
};
