/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { Box, Flex, Image, Text, Button } from 'theme-ui';
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';
import slugify from 'slugify';

import BusinessHeader from '../components/BusinessHeader';
import BusinessSquares from '../components/BusinessSquares';
import BusinessSquaresAlter from '../components/BusinessSquaresAlter';
import AboutComponent from '../components/AboutComponent';
// import EventsModule from '../components/EventsModule'
import PhotosModule from '../components/PhotosModule';
import Layout from '../components/Layout';
// import ShoutCard from '../components/ShoutCard';
import cloudinaryHelper from '../helpers/cloudinaryHelper';
import Left from '../components/icons/Left';

const BusinessPage = ({ pageContext }) => {
  const [galleryItems, setGalleryItems] = useState([]);
  const data = pageContext.data;
  const events = pageContext.events;
  const photos = pageContext.gallery;
  const bizID = data.gonationId;

  const isCommercial = () =>
    pageContext.data.categories.filter(category => category.includes('Commercial')).length > 0;

  console.log('photos: ', photos);

  useEffect(() => {
    axios
      .get('/.netlify/functions/getPhotos', {
        params: {
          albumID: photos.items.filter(itm => itm.album.name === 'OnWashington').length
            ? photos.items.filter(itm => itm.album.name === 'OnWashington')[0].album.id
            : photos.items[0].album.id,
        },
      })
      .then(response => {
        setGalleryItems(response.data.items);
      })
      .catch(e => console.error(e));
  }, []);

  const getImageAmount = () => {
    if (photos.length >= 9) {
      return 12;
    } else return 8;
  };

  return (
    <Layout pageTitle={data.name}>
      <Box
        sx={{
          display: ['block', 'block', 'none'],
          padding: 3,
          marginTop: 4,
        }}></Box>

      <Flex
        as="section"
        sx={{
          my: [4, 4, 6],
          mt: [4, 4, 3],
          mb: '0!important',
          flexDirection: ['column', 'column', 'row'],
        }}>
        <Box
          sx={{
            width: ['100%', '100%', isCommercial() ? '25%' : '50%'],
            marginBottom: [5, 3, 0],
            pr: [0, 0, '32px'],
          }}>
          <Box sx={{ pl: [3, 3, 5] }}>
            <Link to={`/places`}>
              <Left width="30px" />
            </Link>
          </Box>

          <AboutComponent data={data} />
        </Box>
        <Box sx={{ width: ['100%', '100%', isCommercial() ? '75%' : '50%'] }}>
          <PhotosModule photos={galleryItems.slice(0, getImageAmount())} bizName={data.name} />
        </Box>
      </Flex>
      {/* <Box as='section' sx={{ marginY: [4, 4, 6] }}></Box> */}
      {/* <Box as='section'>
            <EventsModule events={events} avatar={data.avatar} />
          </Box> */}
      {/* <Box as='section'>
            
          </Box> */}
    </Layout>
  );
};

export default BusinessPage;
